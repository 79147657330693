.upload-queue {
  position: absolute;
  bottom: 0;
  right: 50px;
  background: #fff;
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 300px;

  h2 {
    margin: 0 0 1rem 0;
    font-size: 1rem;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: scroll;


    .uploading-file {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;


      img {
        min-width: 40px;
        max-width: 40px;
        height: 40px;
      }

      .progress {
        display: block;
        background: #c3e6ff;
        text-align: center;
      }
    }
  }

}
