.splash-screen {

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  
  .select {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .item {
      cursor: pointer;
      text-align: center;

      img {
        height: 250px;
      }

      .title {
        font-size: 1.625rem;
        text-transform: uppercase;
      }
    }
  }
}
